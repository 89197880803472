/* eslint-disable import/no-extraneous-dependencies */
import { RJSFSchema } from '@rjsf/utils';
import i18n from 'i18n/i18n';

const { t } = i18n;
const urlValidationParameter = ['https://pinterest.com', 'https://www.pinterest.com', 'https://ca.pinterest.com'];

export const SmmPinterestSchema: RJSFSchema | any = (data) => ({
  title: t('pages.smmPinterest.solutionStrategy.pageTitle'),
  // eslint-disable-next-line max-len
  description: 'pages.smmPinterest.solutionStrategy.pageDescription',
  type: 'object',
  required: ['existingBusinessPage', 'topicsToPrioritize'],
  properties: {
    existingBusinessPage: {
      type: 'string',
      title: t('pages.smmPinterest.solutionStrategy.clientExistingBusinessPageLabel'),
      default: '',
      tooltip: {
        titleToolTip: true,
        radioButtonToolTip: true,
        titleToolTipText: t('pages.smmPinterest.solutionStrategy.titleToolTipText'),
        radioButtonToolTipText: t('pages.smmPinterest.solutionStrategy.radioButtonToolTipText'),
      },
      enum: [
        'Yes',
        'No',
      ],
    },
    topicsToPrioritize: {
      type: 'string',
      title: t('pages.smmPinterest.solutionStrategy.topicsToPrioritizeLabel'),
      default: '',
    },
    topicsToAvoid: {
      type: 'string',
      title: t('pages.smmPinterest.solutionStrategy.topicsToAvoidLabel'),
      default: '',
    },
    additionalNotes: {
      type: 'string',
      title: t('pages.smmPinterest.solutionStrategy.additionalNotesLabel'),
      default: '',
    },
  },
  dependencies: {
    existingBusinessPage: {
      oneOf: [
        {
          properties: {
            existingBusinessPage: {
              enum: ['Yes'],
            },
            businessPageURL: {
              type: 'string',
              title: t('pages.smmPinterest.solutionStrategy.pinterestBusinessPageURLLabel'),
              default: data?.location?.socials?.[0]?.pinterestUrl || '',
              customUrlValidation: true,
              urlValidationParameter,
              urlValidationErrorText: t('pages.smmPinterest.solutionStrategy.pinterestBusinessPageURLError'),
            },
          },
          required: ['businessPageURL'],
        },
      ],
    },
  },
});

const textFieldKeys = [
  'topicsToPrioritize',
  'topicsToAvoid',
  'additionalNotes',
];

const textFields = textFieldKeys.reduce((o, key) => ({ ...o, [key]: { 'ui:widget': 'TextareaWidget' } }), {});

export const SmmPinterestUISchema = {
  'ui:order': ['existingBusinessPage', 'businessPageURL', 'topicsToPrioritize',
    'topicsToAvoid', 'additionalNotes'],
  existingBusinessPage: {
    'ui:widget': 'radioButtonWithTooltip',
  },
  businessPageURL: {
    'ui:widget': 'UrlTextWidget',
  },
  ...textFields,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SmmPinterestCustomValidate = (formData: any, errors, uiSchema) => {
  if (formData?.existingBusinessPage === 'Yes'
    && !urlValidationParameter.some(url => formData?.businessPageURL?.startsWith(url))) {
    errors.businessPageURL.addError(t('pages.smmPinterest.solutionStrategy.pinterestBusinessPageURLError'));
    errors.addError(true);
  }
  if (formData?.topicsToPrioritize === '') {
    errors.topicsToPrioritize.addError(t('pages.smmPinterest.solutionStrategy.topicsToPrioritizeError'));
    errors.addError(true);
  }
  return errors;
};
