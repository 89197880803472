import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export interface AddressAutocompleteSuggestionTemplateProps {
  address: string;
  name: string;
  place: string;
  region: string;
  country: string;
  includeNameInSuggestion: boolean,
}

const LocationOnIconStyled = styled(LocationOnIcon)(({ theme }) => ({
  color: 'black',
  marginRight: theme.spacing(2),
}));

export const AddressAutocompleteSuggestionTemplate: FC<AddressAutocompleteSuggestionTemplateProps> = ({
  address,
  name,
  place,
  region,
  country,
  includeNameInSuggestion,
}) => (
  <Grid container alignItems="center">
    <Grid item>
      <LocationOnIconStyled />
    </Grid>
    <Grid item xs>
      <Typography variant="body2" color="textPrimary">
        {includeNameInSuggestion && name ? `${name}, ${address}` : address}
      </Typography>
      <Typography variant="body2" color="textPrimary">
        {place ? `${place}, ` : ''}
        {' '}
        {region ? `${region}, ` : ''}
        {' '}
        {country || ''}
      </Typography>
    </Grid>
  </Grid>
);
