import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import useTheme from '@mui/material/styles/useTheme';
import { useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import CoModalConfirm from 'components/modals/CoModalConfirm';
import { selectCategoryAttributes } from 'services/businessSlice';
import GridItem from 'components/grid/GridItem';
import { TitleWithBadge } from 'pages/premium-listings/TitleWithBadge';
import AttributeSelector, { Attribute, AttributeType } from './AttributeSelector';
import { BuildInput } from './BuildInput';

export default function Attributes() {
  const { t } = useTranslation();
  const theme = useTheme();

  const { getValues, watch, setValue } = useFormContext();
  const attributeItems = useSelector(selectCategoryAttributes);
  const { remove } = useFieldArray({ name: 'attributes' });
  const attributes = useMemo(() => getValues('attributes'), [watch('attributes')]);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [attributeToRemove, setAttributeToRemove] = useState(-1);
  const attributesCopy = useMemo(() => JSON.parse(JSON.stringify(attributeItems)), [attributeItems]);

  const onAttributeAdded = (attr) => {
    if (attr.valueType === AttributeType.BOOL) {
      const index = getValues('attributes').length - 1;
      setValue(`attributes.${index}.value`, 'yes');
    }
  };

  const onRemoveAttribute = (index) => {
    setAttributeToRemove(index);
    setShowRemoveDialog(true);
  };

  const cancelRemoveAttribute = () => {
    setShowRemoveDialog(false);
  };

  const deleteAttribute = () => {
    remove(attributeToRemove);
    setShowRemoveDialog(false);
  };

  const deleteWrapperStyle = (attribute: Attribute) => {
    const baseStyle = {
      display: 'flex', height: '100%',
    };

    if (attribute.valueType !== AttributeType.BOOL) {
      return { ...baseStyle, alignItems: 'end' };
    }

    return baseStyle;
  };

  return (
    <>
      <GridItem sizes={[12]}>
        <TitleWithBadge
          title={t('pages.premiumListings.location.info.attributesTitle')}
          badge={t('pages.premiumListings.badge')}
          tooltip={t('pages.premiumListings.badgeTooltip')}
        />
      </GridItem>
      <GridItem sizes={[12]}>
        <AttributeSelector
          name="attributes"
          values={attributes} label
          placeholder={t('pages.premiumListings.location.info.attributesPlaceholder')}
          labelText={t('pages.premiumListings.location.info.attributes')}
          labelTooltipText={t('pages.premiumListings.location.info.attributesTooltip')}
          items={attributesCopy}
          required={false}
          onAttributeAdded={onAttributeAdded}
        />
      </GridItem>
      { attributes.length > 0 && (
        <>
          <GridItem sizes={[12]}>
            <Divider />
          </GridItem>
          {attributes.map((attribute, index) => (
            <GridItem sizes={[11, 1]} key={attribute.id} sx={{ position: 'relative' }}>
              <BuildInput attribute={attribute} index={index} />
              <Box sx={deleteWrapperStyle(attribute)}>
                <Button
                  onClick={() => onRemoveAttribute(index)}
                  sx={{
                    color: theme.palette.primary.main,
                    position: 'absolute',
                    top: 35,
                    right: 0,
                    marginBottom: '5px',
                  }}
                >
                  <DeleteIcon />
                </Button>
              </Box>
              <CoModalConfirm open={showRemoveDialog && attributeToRemove === index}
                title={t('pages.premiumListings.location.info.removeAttributeTitle')}
                description={(
                  <Trans
                    i18nKey="pages.premiumListings.location.info.removeAttributeMessage"
                    values={{ name: attribute.displayName }}
                    components={{ strong: <strong /> }}
                  />
                )}
                confirmText="Remove"
                handleClose={cancelRemoveAttribute}
                handleConfirm={deleteAttribute}
              />
            </GridItem>
          ))}
          <GridItem sizes={[8]}>
            <Divider />
          </GridItem>
        </>
      ) }
    </>
  );
}
