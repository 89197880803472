import AxiosAPI from 'services/common/axios';

export const verifySmsOptInUser = async (params: any) => {
  try {
    const request = {
      url: params.url,
      token: params.token,
    };
    const response = await AxiosAPI.post('public/facebook/sms-opt-in/accounts/verify-token-and-processing-status', request);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const storeSmsOptInDetails = async (details: any) => {
  try {
    const response = await AxiosAPI.post('integrations/force/sms-opt-in', details);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};
