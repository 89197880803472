/* eslint-disable max-len */
/* eslint-disable import/order */
import { SaveAndCloseProvider } from 'context/SaveAndCloseContext';
import { useEffect, useMemo, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { I18nextProvider } from 'react-i18next';
import { Navigate } from 'react-router';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import i18n from './i18n/i18n';

import Maintenance from 'components/maintenance/MaintenanceMode';
import { Urls } from 'navigation/Urls';
import AdminMenu from 'pages/admin/AdminMenu';
import Dashboard from 'pages/admin/Dashboard';
import ManageUsers from 'pages/admin/users/ManageUsers';
import MaintenanceMode from 'pages/admin/MaintenanceMode';
import NewStatus from 'pages/admin/NewStatus';
import Bundles from 'pages/admin/bundles/Bundles';
import EditBundle from 'pages/admin/bundles/EditBundle';
import NewBundle from 'pages/admin/bundles/NewBundle';
import BusinessFunctionalities from 'pages/admin/business-functionalities/BusinessFunctionalities';
import EditFunctionality from 'pages/admin/business-functionalities/EditFunctionality';
import NewFunctionality from 'pages/admin/business-functionalities/NewFunctionality';
import CapacityHeaders from 'pages/admin/capacity-headers/CapacityHeaders';
import NewCapacityStatus from 'pages/admin/capacity-headers/NewCapacityStatus';
import EditCategory from 'pages/admin/market-categories/EditCategory';
import MarketCategories from 'pages/admin/market-categories/MarketCategories';
import NewCategory from 'pages/admin/market-categories/NewCategory';
import EditBenefit from 'pages/admin/theme-benefits/EditFeature';
import NewBenefit from 'pages/admin/theme-benefits/NewBenefit';
import ThemeBenefits from 'pages/admin/theme-benefits/ThemeBenefits';
import EditFamily from 'pages/admin/theme-families/EditFamily';
import NewFamily from 'pages/admin/theme-families/NewFamily';
import ThemeFamilies from 'pages/admin/theme-families/ThemeFamilies';
import EditFeature from 'pages/admin/theme-features/EditFeature';
import NewFeature from 'pages/admin/theme-features/NewFeature';
import ThemeFeatures from 'pages/admin/theme-features/ThemeFeatures';
import AdminThemes from 'pages/admin/themes/AdminThemes';
import EditTheme from 'pages/admin/themes/EditTheme';
import NewTheme from 'pages/admin/themes/NewTheme';
import EditClient from 'pages/business/client/EditClient';
import EditClientMenu from 'pages/business/client/EditClientMenu';
import Carbon from 'pages/carbon/Carbon';
import EditLocationStepper from 'pages/locations/EditLocationStepper';
import MyOrders from 'pages/orders/MyOrders';
import OrdersMenu from 'pages/orders/OrdersMenu';
import {
  RedirectToCobaltClientInvite,
} from 'pages/orders/invite-client/RedirectToCobaltClientInvite';
import OrderReviewMenu from 'pages/orders/review/OrderReviewMenu';
import EditPremiumListingsStepper from 'pages/premium-listings/EditPremiumListingsStepper';
import EditHealthcareLocationStepper from 'pages/premium-listings/healthcare/EditHealthcareLocationStepper';
import EditSalesAgent from 'pages/sales_agent/EditSalesAgent';
import EditSalesAgentMenu from 'pages/sales_agent/EditSalesAgentMenu';
import ShoppingCart from 'pages/shopping-cart/ShoppingCart';
import PunchlistSaved from 'pages/punchlist/saved/PunchlistSaved';
import Themes from 'pages/themes/Themes';
import ThemesMenu from 'pages/themes/ThemesMenu';
import ThemeDetails from 'pages/themes/details/ThemeDetails';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentMaintenanceMode, getMaintenanceModes } from 'services/maintenanceModeSlice';
import {
  getUserInfo,
  selectUserAccess,
  selectUserDataStatus,
  selectUserRole,
} from 'services/userSlice';
import UnexpectedError from './pages/error/UnexpectedError';
import Home from './pages/home/Home';
import Summary from './pages/summary/Summary';
import ActivityLog from 'components/maintenance/ActivityLog';
import GenericProductDetailsMenu from 'pages/generic-product/details/GenericProductDetailsMenu';
import GenericDetailsPage from 'pages/generic-product/details/GenericProductDetailsPage';
import Opportunity from 'pages/salesforce-entry/Opportunity';
import OpportunityMenu from 'pages/salesforce-entry/OpportunityMenu';
import LoadingScreenCef from 'pages/salesforce-entry/loadingScreen';
import SelectStockAssets from 'pages/stock-assets/StockAssets';
import StockAssetsConfirmation from 'pages/stock-assets/StockAssetsConfirmation';
import StockAssetsInvite from 'pages/stock-assets/StockAssetsInvite';
import ProductPageRouter from 'pages/ProductPageRouter';
import ProductMoveBackward from 'pages/redirect/ProductMoveBackward';
import ManageCids from 'pages/admin/manage_cids/ManageCids';
import ManageOffers from 'pages/admin/manage_offers/ManageOffers';
import PunchlistConfirmation from 'pages/punchlist/confirmation/PunchlistConfirmation';
import EntryPage from 'pages/punchlist/EntryPage';
import PunchlistLoadingScreen from 'pages/punchlist/PunchlistLoadingScreen';
import FacebookUrl from 'pages/admin/facebook-url/FacebookUrl';
import ThankYou from 'pages/admin/facebook-url/ThankYou';
import HowToGrantAccess from 'pages/admin/facebook-url/HowToGrantAccess';
import GoogleSearchConsole from 'pages/google-search-console/GoogleSearchConsole';
import ContractUpload from 'pages/admin/contract-upload/ContractUpload';
import GscAccountComponent from 'pages/admin/gsc-account/GscAccountComponent';
import { SendgridChecker } from 'pages/admin/sendgrid-checker/SendgridChecker';
import CefObjectCreator from 'pages/admin/cef-object-creator/CefObjectCreator';
import SmsOptInWebpage from 'pages/public-url/sms-opt-in-webpage/SmsOptInWebpage';
import ExternalSubmissionView from 'pages/orders/review/ExternalSubmissionView';

function App() {
  const dispatch = useDispatch();
  const userRole = useSelector(selectUserRole);
  let underMaintenance = false;
  const baseUrl = process.env.PUBLIC_URL;
  // eslint-disable-next-line prefer-destructuring
  const location = window.location;
  const access = useSelector(selectUserAccess);
  const userStatus = useSelector(selectUserDataStatus);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [canDoCarbon, setCanDoCarbon] = useState(false);
  const [canDoGSC, setCanDoGSC] = useState(false);

  let isPublicUrl = false;

  if (location.href.indexOf('/public/') >= 0) {
    isPublicUrl = true;
  }

  useEffect(() => {
    if (location.href.indexOf('/public/') < 0) {
      dispatch(getMaintenanceModes(null));
      dispatch(getUserInfo('me'));
    }
  }, []);

  useMemo(() => {
    if (access.isAdmin || access.canDoCarbon) {
      setCanDoCarbon(true);
    }
    if (access.isAdmin || access.isGSCIndexer) setCanDoGSC(true);
  }, [access]);

  const maintenanceMode = getCurrentMaintenanceMode();
  if (maintenanceMode === 'ON') {
    underMaintenance = true;
  }

  let canDoCarbonOrIsAdmin = false;
  if (access.isAdmin || access.canDoCarbon) {
    canDoCarbonOrIsAdmin = true;
  }

  if (userRole !== 'admin' && underMaintenance) return (<Maintenance />);
  if (location.href.indexOf('/activitylog') >= 0) return (<ActivityLog />);

  return (
    (isPublicUrl || userStatus === 'idle')
    // eslint-disable-next-line react/jsx-no-useless-fragment
    && (
      <CookiesProvider>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter basename={baseUrl}>
            <SaveAndCloseProvider>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/order/:orderId/product/:orderItemId/:page/:step" element={<ProductPageRouter />} />
                <Route path="/order/:orderId/product/:orderItemId/:page" element={<ProductPageRouter />} />
                <Route path="/order/:orderId/businesses/:businessPage" element={<ProductPageRouter />} />
                <Route path="/order/:orderId/:page" element={<ProductPageRouter />} />

                {/* Edit Pages */}
                <Route path="/order/:orderId/businesses/client/:clientId/edit" element={<EditClientMenu />}>
                  <Route index element={<EditClient />} />
                </Route>
                <Route path="/order/:orderId/businesses/location/:locationId/:step"
                  element={<EditLocationStepper />}
                />
                <Route path="/order/:orderId/product/premiumlistings/location/:locationId/:step"
                  element={<EditPremiumListingsStepper />}
                />
                <Route path="/order/:orderId/product/premiumlistings/healthcare/location/:locationId/:step"
                  element={<EditPremiumListingsStepper />}
                />
                <Route path="/order/:orderId/product/premiumlistings/healthcare/provider/:locationId/:step"
                  element={<EditHealthcareLocationStepper />}
                />
                <Route path="/order/:orderId/salesReps/:agentId/edit" element={<EditSalesAgentMenu />}>
                  <Route index element={<EditSalesAgent />} />
                </Route>

                {/* Select Stock Assets */}
                <Route path="public/order/:shortUrl/design/stockAssets/:token"
                  element={<SelectStockAssets />}
                />
                <Route path="public/thank_you/:shortUrl" element={<StockAssetsConfirmation />} />
                <Route path="public_emails/:orderId/item/:orderItemId" element={<StockAssetsInvite />} />
                <Route path={`/order/:orderId/product/${Urls.ProductMoveBackward}`} element={<ProductMoveBackward />} />
                {/* GenericProduct */}
                <Route path="/order/:orderId/:offeringType/:websiteId/:orderItemId/*" element={<GenericProductDetailsMenu />}>
                  <Route path={Urls.GenericProductDetails} element={<GenericDetailsPage />} />
                </Route>
                {/* Unexpected Error */}
                <Route path={`/order/:orderId/:offeringType/:websiteId/:orderItemId/${Urls.UnexpectedError}`}
                  element={<UnexpectedError />}
                />
                {/* Summary */}
                <Route path="/order/:orderId/:offeringType/:websiteId/summary" element={<Summary />} />
                {/* Themes */}
                <Route path="/ourThemes" element={<ThemesMenu />}>
                  <Route index element={<Themes />} />
                  <Route path="detail" element={<ThemeDetails />} />
                </Route>
                {/* Shopping Cart */}
                <Route path="/shoppingCart" element={<ThemesMenu />}>
                  <Route index element={<ShoppingCart />} />
                  <Route path="cef/:opportunityId" element={<ShoppingCart />} />
                </Route>
                {/* Facebook Url */}
                <Route path="/public/fb/facebook_url">
                  <Route index element={<FacebookUrl />} />
                </Route>
                <Route path="/public/account/:url/fb/facebook_url/:token">
                  <Route index element={<FacebookUrl />} />
                </Route>
                <Route path="/public/fb/facebook_url/how_to_grant_access">
                  <Route index element={<HowToGrantAccess />} />
                </Route>
                <Route path="/public/fb/facebook_url/thank_you">
                  <Route index element={<ThankYou />} />
                </Route>
                {/* SMS Opt-in webpage */}
                <Route path="/public/account/:url/sms_opt_in_webpage/:token">
                  <Route index element={<SmsOptInWebpage />} />
                </Route>
                {/* Admin */}
                <Route element={<AdminMenu />}>
                  {(!access.isAdmin)
                    ? <Route path="/admin/*" element={<Navigate to="/orders/not-authorized" />} />
                    : (
                      <>
                        <Route path="/admin/dashboard" element={<Dashboard />} />
                        <Route path="/admin/maintenanceMode" element={<MaintenanceMode />} />
                        <Route path="/admin/newStatus" element={<NewStatus />} />
                        <Route path="/admin/families">
                          <Route index element={<ThemeFamilies />} />
                          <Route path=":themeId/edit" element={<EditFamily />} />
                          <Route path="new" element={<NewFamily />} />
                        </Route>
                        <Route path="/admin/themeFeatures">
                          <Route index element={<ThemeFeatures />} />
                          <Route path="new" element={<NewFeature />} />
                          <Route path=":featureId/edit" element={<EditFeature />} />
                        </Route>
                        <Route path="/admin/themeBenefits">
                          <Route index element={<ThemeBenefits />} />
                          <Route path="new" element={<NewBenefit />} />
                          <Route path=":benefitId/edit" element={<EditBenefit />} />
                        </Route>
                        <Route path="/admin/marketCategories">
                          <Route index element={<MarketCategories />} />
                          <Route path="new" element={<NewCategory />} />
                          <Route path=":categoryId/edit" element={<EditCategory />} />
                        </Route>
                        <Route path="/admin/businessFunctionalities">
                          <Route index element={<BusinessFunctionalities />} />
                          <Route path="new" element={<NewFunctionality />} />
                          <Route path=":functionalityId/edit" element={<EditFunctionality />} />
                        </Route>
                        <Route path="/admin/capacityHeaders">
                          <Route index element={<CapacityHeaders />} />
                          <Route path="new" element={<NewCapacityStatus />} />
                        </Route>
                        <Route path="/admin/bundles">
                          <Route index element={<Bundles />} />
                          <Route path="new" element={<NewBundle />} />
                          <Route path=":bundleId/edit" element={<EditBundle />} />
                        </Route>
                        <Route path="/admin/themes">
                          <Route index element={<AdminThemes />} />
                          <Route path="new" element={<NewTheme />} />
                          <Route path=":themeId/edit" element={<EditTheme />} />
                        </Route>
                        <Route path="/admin/manage_cids">
                          <Route index element={<ManageCids />} />
                        </Route>
                        <Route path="/admin/manage_product_mapping">
                          <Route index element={<ManageOffers />} />
                        </Route>
                        <Route path="/admin/manage_users">
                          <Route index element={<ManageUsers />} />
                        </Route>
                        <Route path="/admin/gsc_account">
                          <Route index element={<GscAccountComponent />} />
                        </Route>
                        <Route path="/admin/cef_object_creator">
                          <Route index element={<CefObjectCreator />} />
                        </Route>
                      </>
                    )}
                  <Route path="/sendgrid-checker">
                    <Route index element={<SendgridChecker />} />
                  </Route>
                  <Route path="/airkit-archive">
                    <Route index element={<ContractUpload />} />
                  </Route>
                </Route>
                { (!canDoGSC)
                  ? <Route path="/google_search_console" element={<Navigate to="/orders/not-authorized" />} />
                  : (
                    <Route path="/google_search_console">
                      <Route index element={<GoogleSearchConsole />} />
                    </Route>
                  )}
                {/* Themes */}
                <Route path="/ourThemes" element={<ThemesMenu />}>
                  <Route index element={<Themes />} />
                  <Route path="detail" element={<ThemeDetails />} />
                </Route>

                {/* Orders */}
                <Route element={<OrdersMenu />}>
                  <Route path={Urls.Orders}>
                    <Route index element={<MyOrders />} />
                    <Route path="not-authorized" element={<MyOrders />} />
                    <Route path="quoteRequests" element={<MyOrders />} />
                    <Route path="punchList" element={<MyOrders />} />
                    <Route path="mail/success/" element={<MyOrders />} />
                    <Route path="mail/fail/" element={<MyOrders />} />
                    <Route path="review/finalized/" element={<MyOrders />} />
                  </Route>
                  <Route path="order/:orderId" element={<OrderReviewMenu />} />
                  <Route path="/order/:orderId/external-submission-view" element={<ExternalSubmissionView />} />
                  <Route path="order/:orderId/review" element={<OrderReviewMenu />} />
                  <Route path="order/:orderId/product/:productId" element={<OrderReviewMenu />} />
                </Route>
                {/* Carbon */}
                {(!canDoCarbonOrIsAdmin)
                  ? <Route path="/carbon" element={<Navigate to="/orders/not-authorized" />} />
                  : <Route path="carbon" element={<Carbon />} />}
                {/* CarbonMenu() */}
                <Route path="/publicEmails/:orderId" element={<RedirectToCobaltClientInvite />} />

                {/* Salesforce Entry */}
                <Route path="/cef" element={<LoadingScreenCef />} />
                <Route path="/cef/:opportunityId" element={<OpportunityMenu />}>
                  <Route index element={<Opportunity />} />
                  <Route path=":solutionId" element={<Opportunity />} />
                  <Route path="offer/:offerId/detail" element={<ThemeDetails />} />
                </Route>

                {/* Punchlist */}
                <Route path="punchlist/:orderId/confirmation" element={<PunchlistConfirmation />} />
                <Route path={`/${Urls.Punchlist}`} element={<PunchlistLoadingScreen />} />
                <Route path={`/${Urls.Punchlist}/:caseId`} element={<EntryPage />} />
                <Route path="/saved/punchListSaved" element={<PunchlistSaved />} />
              </Routes>
            </SaveAndCloseProvider>
          </BrowserRouter>
        </I18nextProvider>
      </CookiesProvider>
    ));
}

export default App;
