import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
  Autocomplete,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import {
  debounceTime, fromEvent, map, Subject,
} from 'rxjs';
import schemaValidate from 'components/schemaValidate';
import { NeFormSelectProps } from 'components/formSelect/NeFormSelect';
import LabelTooltip from 'components/labels/LabelTooltip';
import ErrorText from 'components/errorText/ErrorText';
import { AddressAutocompleteSuggestionTemplate } from './AddressAutocompleteSuggestionTemplate';

interface NeAddressSearchParams {
  query: string;
}

export interface NeAddressMapboxSuggestionProps {
  id: string;
  name: string;
  address: string;
  place: string;
  region: string;
  postalCode: string;
  country: string;
  relevance: number;
  matchingText: any;
  matchingPlaceName: any;
  centerPoint: number[];
  includeNameInSuggestion: boolean;
}

export interface IQAddressMapboxProps extends Omit<NeFormSelectProps, 'items'> {
  items: NeAddressMapboxSuggestionProps[];
  onAutoCompleteSelected?: (value: any) => void;
  onAddressChange: any;
  getAddressOptions: (params: NeAddressSearchParams) => void;
  schema?: any;
  customValidation?: any;
  loadingText?: string;
  tooltipText?: string;
  searchDebounceTimeMS?: number;
}

type IQLabelStyledProps = {
  hasError: boolean;
};

const useDebounce = (time, initialValue) => {
  const [value, setValue] = useState(initialValue);
  const [values] = useState(() => new Subject());
  useEffect(() => {
    const sub = values.pipe(debounceTime(time)).subscribe(setValue);
    return () => sub.unsubscribe();
  }, [time, values]);
  return [value, (v) => values.next(v)];
};

const TextFiledStyled = styled(TextField)<TextFieldProps>(
  ({ theme, error }) => ({
    fontSize: 16,
    fontFamily: 'Unify Sans',
    paddingTop: theme.spacing(2),
    color: 'black',
    '& .Mui-error': {
      marginLeft: 0,
      color: theme.palette.error.main,
    },
    '& > .MuiInputBase-root': {
      height: 40,
      borderRadius: 0,
      '& > input.MuiAutocomplete-input': {
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& > fieldset': {
        borderColor: 'black',
      },
      '& .MuiAutocomplete-clearIndicator': {
        color: error ? theme.palette.error.main : theme.palette.text.primary,
      },
    },
  }),
);

const StyledFormLabel = styled('label')<IQLabelStyledProps>(
  ({ onError, theme }) => ({
    color: onError ? theme.palette.error.main : theme.palette.text.primary,
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.25px',
    fontFamily: 'Unify Sans',
    paddingBottom: theme.spacing(1),
    fontWeight: 700,
    display: 'inline-block',
    width: '100%',
  }),
);

const NeAddressMapbox: FC<IQAddressMapboxProps> = ({
  onAutoCompleteSelected,
  onAddressChange,
  disabled,
  labelText,
  tooltipText,
  name,
  items = [],
  id = 'business-address-input',
  defaultAddress,
  getAddressOptions,
  schema,
  customValidation,
  loadingText = 'Loading...',
  searchDebounceTimeMS = 500,
}) => {
  const [addressInputValue, setAddressInputValue] = useState('');
  const [queryValue, setQueryValue] = useDebounce(searchDebounceTimeMS, '');

  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const hasError = !!errors[name];
  const businessAddrWatch = watch(name);

  const onAutocompleteChange = (event, newValue) => {
    // If the value was wiped leave these fields in place.
    if (newValue) {
      onAutoCompleteSelected(newValue);
    }
  };

  // Need this in case they don't select an autocomplete option
  useEffect(() => {
    const autoCompleteUsage = fromEvent(document.getElementById(id), 'blur');
    const result = autoCompleteUsage
      .pipe(
        map((i) => {
          const currentTarget = i.currentTarget as HTMLInputElement;
          return currentTarget.value;
        }),
        debounceTime(searchDebounceTimeMS),
      )
      .subscribe((keyValue) => onAddressChange(keyValue));

    return () => result.unsubscribe();
  }, []);

  useEffect(() => {
    // When the address input value changes, dispatch an action to
    // get new suggestions. Debouncing these requests to not overload the API.
    if (queryValue && queryValue !== '') {
      getAddressOptions({
        query: queryValue,
      });
    }
  }, [queryValue]);

  useEffect(() => {
    if (
      businessAddrWatch !== null
            && businessAddrWatch !== undefined
            && businessAddrWatch !== addressInputValue
    ) {
      setAddressInputValue(businessAddrWatch);
    }
  }, [setAddressInputValue, businessAddrWatch]);

  return (
    <Autocomplete
      id={id}
      autoComplete
      freeSolo
      loadingText={loadingText}
      defaultValue={!defaultAddress ? '' : defaultAddress}
      inputValue={!addressInputValue ? '' : addressInputValue}
      options={items || []}
      filterOptions={(x) => x}
      disabled={disabled || false}
      openOnFocus
      onChange={onAutocompleteChange}
      onInputChange={(_, newInputValue) => {
        setAddressInputValue(newInputValue);
        setQueryValue(newInputValue);
      }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.address)}
      renderInput={(params) => (
        <StyledFormLabel hasError={hasError}>
          <LabelTooltip
            labelText={labelText}
            tooltipText={tooltipText}
            hasError={hasError}
            arrow={false}
          />
          <TextFiledStyled
            {...params}
            label={null}
            variant="outlined"
            name={name}
            error={hasError}
            disabled={disabled || false}
            helperText={<ErrorText hasError={!!errors[name]} errorText={errors[name]?.message as string} />}
            {...register(name, {
              validate: (value) => schemaValidate(value, name, schema, params, customValidation),
            })}
          />
        </StyledFormLabel>
      )}
      renderOption={(htmlOption, dataOption) => (
        <li {...htmlOption} key={dataOption.id}>
          <AddressAutocompleteSuggestionTemplate
            address={dataOption.address}
            name={dataOption.name}
            includeNameInSuggestion={dataOption.includeNameInSuggestion}
            place={dataOption.place}
            region={dataOption.region}
            country={dataOption.country}
          />
        </li>
      )}
    />
  );
};

export default NeAddressMapbox;
