import React, { Fragment, useState } from 'react';
import { Close, HelpOutline } from '@mui/icons-material';
import {
  Link, Typography, Backdrop, Container, SvgIconProps,
} from '@mui/material';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

export enum IconPosition {
  BEFORE_POSITION = 'before',
  AFTER_POSITION = 'after',
}

// Constants to use for the tooltipPosition param
export const BEFORE_POSITION = 'before';
export const AFTER_POSITION = 'after';

export interface InfoTooltipWrapperProps {
  tooltipText: any;
  infoClass?: string;
  CustomIcon?: React.ComponentType<SvgIconProps>;
  tooltipIconPosition?: 'after' | 'before';
  children?: any;
}

const IconNode = styled(HelpOutline)(({ theme }) => ({
  height: 18,
  width: 18,
  paddingLeft: 4,
  top: 2,
  position: 'relative',
  color: theme.palette.primary.main,
  cursor: 'pointer',
}));

const Wrapper = styled(Link)({
  whiteSpace: 'pre-wrap',
  textAlign: 'left',
}) as typeof Link;

const InfoText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const HoverOverlay = styled(Backdrop)`
  display: block;
  padding: 60px 0px;
  position: fixed;
  z-index: 1200;
  background-color: ${(props) => props.theme.palette.common.black};
  color: ${(props) => props.theme.palette.common.white};
  flex-wrap: wrap;
  align-content: center;
`;

const IconWrapper = styled(Container)({
  textAlign: 'right',
  paddingBottom: 24,
});

const IconSX = (theme) => (
  {
    height: '18px',
    width: '18px',
    paddingLeft: '4px',
    top: '2px',
    position: 'relative',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  }
);

/**
 * A wrapping component that adds a tooltip and info icon to the given children elements
 */
const InfoTooltipWrapper = ({
  tooltipText, infoClass, CustomIcon, tooltipIconPosition = IconPosition.AFTER_POSITION, children,
}: InfoTooltipWrapperProps) => {
  const theme = useTheme();
  const [openTooltip, setOpenTooltip] = useState(false);

  const Icon = CustomIcon ? <CustomIcon sx={IconSX(theme)} /> : <IconNode />;

  const handleClose = (event) => {
    event.preventDefault();
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  return (
    <>
      <Wrapper variant="body1" component="button" type="button"
        underline="none" onClick={handleTooltipOpen}
      >
        <InfoText className={`${infoClass}`}>
          {tooltipIconPosition === BEFORE_POSITION
                        && Icon}
          {children}
          {tooltipIconPosition === AFTER_POSITION
                        && Icon}
        </InfoText>
      </Wrapper>
      <HoverOverlay open={openTooltip}>
        <IconWrapper onClick={handleClose}>
          <Close sx={{ cursor: 'pointer' }} />
        </IconWrapper>
        <Container>
          <Typography component="div">{tooltipText}</Typography>
        </Container>
      </HoverOverlay>
    </>
  );
};

export default InfoTooltipWrapper;
