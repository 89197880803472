import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { backgroundDefaultColor } from 'styles/common_styles';
import Button from '@mui/material/Button';
import AxiosAPI from 'services/common/axios';
import { Box } from '@mui/system';

import { useNavigate } from 'react-router';
import { t } from 'i18next';
import {
  buttonStyle,
  fileNameStyle,
  uploadContainerStyle,
} from 'pages/google-search-console/GscUtility';

export default function GscAccountComponent() {
  const [accountName, setAccountName] = useState('');
  const [accountId, setAccountId] = useState('');
  const [credentialsFile, setCredentialsFile] = useState(null);
  const [credentialsFileName, setCredentialsFileName] = useState(t('gscIndexing.noFileChosen'));
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [hoverEnable, setHoverEnable] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleCredentialsFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCredentialsFile(file);
      setCredentialsFileName(file.name);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('accountName', accountName);
      formData.append('accountId', accountId);
      formData.append('credentialsFile', credentialsFile);
      await AxiosAPI.post('gsc-index/save-credentials', formData);
      setSnackbarOpen(true);
      setTimeout(() => {
        setAccountName('');
        setAccountId('');
      }, 800);
      setTimeout(() => {
        setCredentialsFile(null);
        navigate('/google_search_console');
      }, 3000);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  function setAccountData(value: string) {
    setAccountName(value);
    setAccountId(value);
  }

  return (
    <Paper elevation={0} sx={{ backgroundColor: backgroundDefaultColor }}>
      <Typography variant="h2" mb={3} px={6}>{t('gscIndexing.googleSearchConsole')}</Typography>
      <Grid px={6}>
        <Box>
          <Typography variant="h4">
            {t('gscIndexing.createAccount')}
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography variant="h6">
            <h3>{t('gscIndexing.enterAccountId')}</h3>
          </Typography>
        </Box>
        <TextField
          label="Account Id"
          variant="outlined"
          value={accountId}
          onChange={(e) => setAccountData(e.target.value)}
          fullWidth
          margin="normal"
        />
        {accountId !== '' && (
          <>
            <Box mt={1}>
              <Typography variant="h6">
                {t('gscIndexing.uploadCredentialFile')}
              </Typography>
            </Box>
            <Box mt={1} style={uploadContainerStyle}>
              <input
                type="file"
                id="credentials-file-upload"
                accept=".json"
                onChange={(e) => handleCredentialsFileUpload(e)}
                style={{ display: 'none' }}
              />
              <label htmlFor="credentials-file-upload" className="file-label">
                <span
                  style={buttonStyle(hoverEnable)}
                  onMouseEnter={() => setHoverEnable(true)}
                  onMouseLeave={() => setHoverEnable(false)}
                >
                  {t('gscIndexing.chooseCredentialsFile')}
                </span>
                <span style={fileNameStyle}>{credentialsFileName}</span>
              </label>
            </Box>
          </>
        )}
        <Box mt={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button sx={{ height: '50px' }} variant="contained"
            onClick={() => handleSubmit()}
          >
            {t('gscIndexing.createAccount')}
          </Button>
        </Box>
        <Snackbar
          open={snackbarOpen}
          onClose={handleCloseSnackbar}
          autoHideDuration={3000}
          message={t('gscIndexing.accountCreatedMessage')}
        />
      </Grid>
    </Paper>
  );
}
