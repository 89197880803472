import {
  IQButtonLink, IQLoadingSpinner, IQThemeV2, ValidationProvider,
} from '@gannettdigital/shared-react-components';
import IQFileUploader from 'components/fileUploader/NeFileUploader';
import { DefaultPageLayout } from 'layouts/DefaultPageLayout';
import { FormProvider, useForm } from 'react-hook-form';
import {
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import { Trans } from 'react-i18next';
import i18n from 'i18n/i18n';
import React, { useEffect, useState } from 'react';
import schemaValidate from 'components/schemaValidate';
import { inputStyles } from 'pages/seo/details/DetailsPageForm';
import ErrorText from 'components/errorText/ErrorText';
import {
  FileUploadProductType,
  ProductTypes, deleteFile, getFiles, selectFilesUploaded, uploadFiles,
} from 'services/fileUploaderSlice';
import { useDispatch, useSelector } from 'react-redux';
import CoFileList from 'components/fileList/CoFileList';
import NeDropdown from 'components/dropdown/NeDropdown';
import CoNavigationConfirm from 'components/navigation/CoNavigationConfirm';
import { NeFormTextArea } from 'components/formTextArea/NeFormTextArea';
import GridItem from 'components/grid/GridItem';
import SocialMediaAccounts from 'pages/locations/contact/SocialMediaAccounts';
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';
import { selectOrdersContent } from 'services/ordersSlice';
import { getBusinessLocations, selectBusinessLocations } from 'services/businessLocationSlice';
import NePhoneInput from 'components/NePhoneInputContainer/IQPhoneInputContainer';
import { selectActiveOfferingType, selectActiveProductId } from 'services/navigationSlice';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import schema from './CCByDashStrategy.schema';
import { smsWidgetColorOptions, socialMediaAccounts, yesNoOptions } from './CCByDashStrategyConstants';
import PhoneTracking from './phone-tracking/PhoneTracking';
import PhoneTrackingForm from './phone-tracking/PhoneTrackingForm';
import TrackingLineCard from './phone-tracking/TrackingLineCard';
import SMSLeadEmails from './sms-lead-emails/SMSLeadEmails';

const { t } = i18n;

interface Props {
  product: any
  onBack: any
  onSubmit: any
}

const emptyTrackingLine = {
  originalNumber: '',
  forwardToNumber: '',
  areaCodePreference: '',
};

export default function CCByDashStrategyForm(props: Props) {
  const { product } = props;
  const websiteId = useSelector(selectActiveProductId);
  const offeringType = useSelector(selectActiveOfferingType);
  const dispatch = useDispatch();
  const filesUploaded = useSelector(selectFilesUploaded);
  const order = useSelector(selectOrdersContent);
  const locations = useSelector(selectBusinessLocations);
  const [files, setFiles] = useState([]);
  const [phoneTracking, setPhoneTracking] = useState(product?.phoneTracking || '');
  const [linkSocialMedia, setLinkSocialMedia] = useState(product?.linkSocialMedia || '');
  const [smsAiAgentOptions, setSmsAiAgentOptions] = useState(product?.smsAiAgentOptions || '');
  const [utilizeSamePrimaryUrl, setUtilizeSamePrimaryUrl] = useState(product?.utilizeSamePrimaryUrl || '');
  const [activeChatWidget, setActiveChatWidget] = useState(product?.activeChatWidget || '');
  const [isTrackingLineOpen, setIsTrackingLineOpen] = useState(false);
  const [trackingLines, setTrackingLines] = useState(product?.trackingLines || []);
  const [trackingLine, setTrackingLine] = useState(emptyTrackingLine);
  const [trackingLineError, setTrackingLineError] = useState(false);

  const uploaderType = ProductTypes.EXISTING_CONTACT_LIST;
  const megabyte = 1_000_000;
  const defaultFileSize = 256 * megabyte;

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      phoneTracking: product?.phoneTracking || '',
      trackingLines: product?.trackingLines || [],
      edgeLeadEmail: product?.edgeLeadEmail || '',
      linkSocialMedia: product?.linkSocialMedia || '',
      accounts: product?.accounts || socialMediaAccounts,
      activeChatWidget: product?.activeChatWidget || '',
      smsWidgetColor: product?.smsWidgetColor || 'Slate Grey',
      localAreaCode1: product?.localAreaCode1 || '',
      localAreaCode2: product?.localAreaCode2 || '',
      smsLeadEmailList: product?.smsLeadEmailList || [''],
      smsLeadPhone: product?.smsLeadPhone || '',
      additionalNotes: product?.additionalNotes || '',
      smsAiAgentOptions: product?.smsAiAgentOptions || '',
      utilizeSamePrimaryUrl: product?.utilizeSamePrimaryUrl || '',
      siteUrl: product?.siteUrl || '',
    },
  });

  const {
    register,
    watch,
    setValue,
    getValues,
    handleSubmit,
    reset,
    control,
    formState: {
      isDirty,
      isSubmitSuccessful,
      isSubmitting,
      isValid,
      errors,
    },
  } = methods;

  const showPhoneTracking = watch('phoneTracking') === 'yes';
  const showSmsAiAgentFields = watch('smsAiAgentOptions') === 'yes';
  const isDifferentPrimaryUrl = watch('utilizeSamePrimaryUrl') === 'no';
  const showSocialMediaAccounts = watch('linkSocialMedia') === 'yes';
  const noAccounts = watch('accounts').filter(account => account.enabled).length === 0;
  const getSocial = (name, location) => location?.socials?.[0]?.[name] || '';
  const showSmsWidgetColor = watch('activeChatWidget') === 'no';

  useEffect(() => {
    if (order?.businessId) dispatch(getBusinessLocations(order.businessId));
  }, [order]);

  useEffect(() => {
    if (product) {
      reset({
        phoneTracking: product?.phoneTracking || '',
        trackingLines: product?.trackingLines || [],
        edgeLeadEmail: product?.edgeLeadEmail || '',
        linkSocialMedia: product?.linkSocialMedia || '',
        accounts: product?.accounts || socialMediaAccounts,
        activeChatWidget: product?.activeChatWidget || '',
        smsWidgetColor: product?.smsWidgetColor || 'Slate Grey',
        localAreaCode1: product?.localAreaCode1 || '',
        localAreaCode2: product?.localAreaCode2 || '',
        smsLeadEmailList: product?.smsLeadEmailList || [''],
        smsLeadPhone: product?.smsLeadPhone || '',
        additionalNotes: product?.additionalNotes || '',
        smsAiAgentOptions: product?.smsAiAgentOptions || '',
        utilizeSamePrimaryUrl: product?.utilizeSamePrimaryUrl || '',
        siteUrl: product?.siteUrl || '',
      });
    }
  }, [product]);

  useEffect(() => {
    if (locations.length > 0 && (!product.accounts || product?.linkSocialMedia === '')) {
      const location = locations[locations.length - 1];

      const facebookUrl = getSocial('facebookUrl', location);
      const instagramUrl = getSocial('instagramUrl', location);
      const twitterUrl = getSocial('twitterUrl', location);
      const youtubeUrl = getSocial('youtubeUrl', location);

      const hasSocials = (facebookUrl || instagramUrl || twitterUrl || youtubeUrl);
      if (hasSocials && showSocialMediaAccounts) {
        const socials = [
          {
            name: 'Facebook',
            enabled: !!facebookUrl,
            url: facebookUrl || '',
            applyToAll: false,
          },
          {
            name: 'Twitter',
            enabled: !!twitterUrl,
            url: twitterUrl || '',
            applyToAll: false,
          },
          {
            name: 'Instagram',
            enabled: !!instagramUrl,
            url: instagramUrl || '',
            applyToAll: false,
          },
          {
            name: 'YouTube',
            enabled: !!youtubeUrl,
            url: youtubeUrl || '',
            applyToAll: false,
          },
        ];

        setValue('accounts', socials);
        socials?.forEach((account, index) => setValue(`accounts.${index}`, account));
      }
    }
  }, [locations, showSocialMediaAccounts]);

  useEffect(() => {
    if (!showSocialMediaAccounts) setValue('accounts', socialMediaAccounts);
  }, [showSocialMediaAccounts]);

  useEffect(() => {
    if (!showPhoneTracking) setTrackingLines([]);
  }, [showPhoneTracking]);

  useEffect(() => {
    const filteredFiles = filesUploaded.content
      .filter(file => file.productType === FileUploadProductType.DMSNextCustomerCenterByDash);
    setFiles(filteredFiles);
  }, [filesUploaded]);

  const onUpdate = (newFiles) => {
    dispatch(uploadFiles({
      websiteId, files: newFiles, uploaderType, offeringType,
    }));
  };

  const onDelete = (file) => {
    dispatch(deleteFile({ websiteId, file }));
  };

  useEffect(() => {
    dispatch(getFiles({ websiteId, uploaderType, offeringType }));
  }, [websiteId, offeringType]);

  const openModal = (content) => {
    setIsTrackingLineOpen(true);
    setTrackingLine(content);
  };

  const closeModal = () => {
    setIsTrackingLineOpen(false);
    setTrackingLine(emptyTrackingLine);
  };

  const addTrackingLine = (data) => {
    setIsTrackingLineOpen(false);
    const linesCopy = [...trackingLines];
    if (!data.id) {
      linesCopy.push({ ...data, id: uuidv4() });
    } else {
      const itemIndex = trackingLines.findIndex(line => line.id === data.id);
      linesCopy[itemIndex] = data;
    }
    setTrackingLines(linesCopy);
    setTrackingLineError(false);
  };

  const deleteTrackingLine = (id) => {
    const itemIndex = trackingLines.findIndex(line => line.id === id);
    if (itemIndex !== -1) {
      const linesCopy = [...trackingLines];
      linesCopy.splice(itemIndex, 1);
      setTrackingLines(linesCopy);
      if (linesCopy.length === 0) setTrackingLineError(true);
    }
  };

  const editTrackingLine = (id) => {
    setIsTrackingLineOpen(true);
    const itemToEdit = trackingLines.filter(line => line.id === id)?.[0];
    if (itemToEdit) setTrackingLine(itemToEdit);
  };

  useEffect(() => {
    setValue('trackingLines', trackingLines);
  }, [trackingLines]);

  const validateForm = () => {
    if (getValues('phoneTracking') === '' || (showPhoneTracking && trackingLines.length === 0)) return true;
    if (showSocialMediaAccounts && noAccounts) return true;
    return !isValid;
  };

  const handleFormSubmit = (data) => props.onSubmit(data);

  return (
    <DefaultPageLayout
      disableContinue={validateForm()}
      onBack={props.onBack}
      onContinue={handleSubmit(handleFormSubmit)}
      header="Solution Strategy"
      description={(
        <Trans
          i18nKey="pages.ccByDash.solutionStrategy.pageDescription"
          components={{ br: <br /> }}
        />
      )}
    >
      <ValidationProvider schema={schema}>
        <FormProvider {...methods}>
          <Grid container xs={9} sx={{ ...inputStyles }}>
            {/* Phone Tracking */}
            <Grid item mb={1} xs={12}>
              <Grid item mb={1}>
                <Typography variant="subtitle2" component="h3" fontSize={16}>
                  {t('pages.ccByDash.solutionStrategy.phoneTrackingLabel')}
                </Typography>
                <RadioGroup
                  aria-labelledby="phone-tracking"
                  name="phoneTracking"
                  value={phoneTracking}
                  onChange={(e) => setPhoneTracking(e.target.value)}
                  sx={{ display: 'flex', flexDirection: 'row' }}
                >
                  {yesNoOptions.map(option => (
                    <FormControlLabel
                      key={option.value}
                      {...register('phoneTracking', {
                        validate: (value) => schemaValidate(value, 'phoneTracking', schema),
                      })}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </Grid>
            </Grid>
            {/* Phone Tracking Options */}
            {showPhoneTracking && (
              <>
                {React.Children.toArray(trackingLines.map((line, index) => (
                  <Grid item xs={12} mb={3}>
                    <TrackingLineCard
                      onEdit={editTrackingLine}
                      onDelete={deleteTrackingLine}
                      index={index}
                      data={line}
                    />
                  </Grid>
                )))}
                <Grid item xs={12} mb={1}
                  sx={{
                    padding: '10px 0',
                    backgroundColor: IQThemeV2.palette.primary.light,
                  }}
                >
                  <IQButtonLink
                    startIcon={<AddIcon />}
                    onClick={() => openModal(emptyTrackingLine)}
                  >
                    Add a Tracking Line
                  </IQButtonLink>
                </Grid>
                <Grid xs={12} mb={3}>
                  <ErrorText
                    hasError={trackingLineError}
                    errorText="Please add at least one tracking line."
                  />
                </Grid>
                <PhoneTracking
                  isOpen={isTrackingLineOpen}
                  onClose={closeModal}
                >
                  <PhoneTrackingForm
                    addNewLine={addTrackingLine}
                    closeModal={closeModal}
                    content={trackingLine}
                  />
                </PhoneTracking>
              </>
            )}
            {/* Edge Lead Notification Email */}
            <Grid item mb={3} xs={12}>
              <Typography variant="subtitle2" component="h3" fontSize={16}
                color={errors.edgeLeadEmail ? 'error.main' : 'common.black'} mb={1}
              >
                {t('pages.ccByDash.solutionStrategy.edgeLeadEmailLabel')}
              </Typography>
              <TextField
                {...register('edgeLeadEmail', {
                  validate: (value) => schemaValidate(value, 'edgeLeadEmail', schema),
                })}
                hiddenLabel
                required={false}
                name="edgeLeadEmail"
                type="email"
                error={!!errors.edgeLeadEmail}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <ErrorText
                hasError={!!errors.edgeLeadEmail}
                errorText="Please enter a valid email in this format: name@example.com."
              />
            </Grid>
            {/* Existing Content */}
            <Grid item xs={12} sx={{
              '& > div > div': { padding: 0, display: 'flex', justifyContent: 'flex-start' },
              '& > div > div > *': { marginLeft: '10px' },
            }}
            >
              <IQFileUploader
                showRecommendedImageText={false}
                showAcceptedFileTypesText={false}
                required={false}
                name="uploads" maxFileSize={defaultFileSize}
                onSuccess={onUpdate}
                label={t('pages.ccByDash.solutionStrategy.existingContentLabel')}
                supportingText=""
                totalAllowedFiles={1}
                videoTypes={[]}
                documentTypes={['.pdf', '.docx', '.doc', '.csv', '.xlsx']}
                size="md"
                currentUploadedFiles={files.length}
              />
            </Grid>
            <Grid item xs={12} mb={1}
              mt={0}
            >
              {filesUploaded.status === 'loading' && <IQLoadingSpinner size={40} />}
              {files.length
                ? <CoFileList files={files} onDelete={onDelete} />
                : null }
            </Grid>
            {/* Link Social Media */}
            <Grid item mb={3} xs={12}>
              <Grid item mb={1}>
                <Typography variant="subtitle2" component="h3" fontSize={16}>
                  {t('pages.ccByDash.solutionStrategy.linkSocialMediaLabel')}
                </Typography>
                <RadioGroup
                  aria-labelledby="link-social-media"
                  name="linkSocialMedia"
                  value={linkSocialMedia}
                  onChange={(e) => setLinkSocialMedia(e.target.value)}
                  sx={{ display: 'flex', flexDirection: 'row' }}
                >
                  {yesNoOptions.map(option => (
                    <FormControlLabel
                      key={option.value}
                      {...register('linkSocialMedia', {
                        validate: (value) => schemaValidate(value, 'linkSocialMedia', schema),
                      })}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </Grid>
            </Grid>
            {/* Social Media Accounts */}
            {showSocialMediaAccounts && (
            <GridItem sizes={[12]}>
              <SocialMediaAccounts
                showApplyToAll={false}
                showSectionLabels={false}
              />
            </GridItem>
            )}
            {/* Active Chat Widget */}
            <Grid item mb={1} xs={12}>
              <Grid item mb={1}>
                <Typography variant="subtitle2" component="h3" fontSize={16}>
                  {t('pages.ccByDash.solutionStrategy.activeChatWidgetLabel')}
                </Typography>
                <RadioGroup
                  aria-labelledby="active-chat-widget"
                  name="activeChatWidget"
                  value={activeChatWidget}
                  onChange={(e) => setActiveChatWidget(e.target.value)}
                  sx={{ display: 'flex', flexDirection: 'row' }}
                >
                  {yesNoOptions.map(option => (
                    <FormControlLabel
                      key={option.value}
                      {...register('activeChatWidget', {
                        validate: (value) => schemaValidate(value, 'activeChatWidget', schema),
                      })}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </Grid>
            </Grid>
            {/* SMS Widget Color */}
            {showSmsWidgetColor && (
              <Grid item mb={3} xs={12}>
                <Grid item mb={1}>
                  <NeDropdown
                    name="smsWidgetColor"
                    label={t('pages.ccByDash.solutionStrategy.smsWidgetColorLabel')}
                    {...register('smsWidgetColor', { required: 'This is required' })}
                    required
                    defaultValue={product?.smsWidgetColor || 'Slate Grey'}
                  >
                    {smsWidgetColorOptions.map(opt => (
                      <MenuItem value={opt} key={opt}>{opt}</MenuItem>
                    ))}
                  </NeDropdown>
                </Grid>
              </Grid>
            )}
            {/* Local Area Code 1 */}
            <Grid item mb={3} xs={12}>
              <Grid item mb={1}>
                <Typography variant="subtitle2" component="h3" fontSize={16}
                  color={errors.localAreaCode1 ? 'error.main' : 'common.black'}
                >
                  {t('pages.ccByDash.solutionStrategy.localAreaCode1Label')}
                </Typography>
              </Grid>
              <TextField
                hiddenLabel
                required
                name="localAreaCode1"
                error={!!errors.localAreaCode1}
                fullWidth
                {...register('localAreaCode1', {
                  validate: (value) => schemaValidate(value, 'localAreaCode1', schema),
                })}
              />
              <ErrorText
                hasError={!!errors.localAreaCode1}
                errorText={t('pages.ccByDash.solutionStrategy.localAreaCode1Error')}
              />
            </Grid>
            {/* Local Area Code 2 */}
            <Grid item mb={3} xs={12}>
              <Grid item mb={1}>
                <Typography variant="subtitle2" component="h3" fontSize={16}
                  color="common.black"
                >
                  {t('pages.ccByDash.solutionStrategy.localAreaCode2Label')}
                </Typography>
              </Grid>
              <TextField
                hiddenLabel
                required={false}
                name="localAreaCode2"
                error={!!errors.localAreaCode2}
                fullWidth
                {...register('localAreaCode2', {
                  validate: (value) => schemaValidate(value, 'localAreaCode2', schema),
                })}
              />
            </Grid>

            {/* SMS AI Agent Enablement */}
            <Grid item mb={1} xs={12}>
              <Typography variant="subtitle2" component="h3" fontSize={16}>
                {t('pages.ccByDash.solutionStrategy.enableSmsAiAgentLabel')}
              </Typography>
              <RadioGroup
                aria-labelledby="sms-ai"
                name="smsAiAgent"
                value={smsAiAgentOptions}
                onChange={(e) => setSmsAiAgentOptions(e.target.value)}
                sx={{ display: 'flex', flexDirection: 'row' }}
              >
                {yesNoOptions.map(option => (
                  <FormControlLabel
                    key={option.value}
                    {...register('smsAiAgentOptions', {
                      validate: (value) => schemaValidate(value, 'smsAiAgentOptions', schema),
                    })}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
              <FormHelperText>{t('pages.ccByDash.solutionStrategy.smsAiAgentHelpText')}</FormHelperText>
            </Grid>

            {showSmsAiAgentFields && (
            <Grid>
              {/* SMS Lead Notification Email */}
              <Grid item mb={3} xs={12}>
                <SMSLeadEmails getValues={getValues} setValue={setValue} schema={schema}
                  watch={watch} control={control}
                />
              </Grid>
              {/* SMS Lead Notification Phone Number */}
              <Grid item mb={3} xs={12}>
                <NePhoneInput
                  name="smsLeadPhone"
                  defaultValue=""
                  country="US"
                  hasExtension={false}
                  phoneLabelOptions={{
                    tooltipText: '',
                    labelText: t('pages.ccByDash.solutionStrategy.smsLeadPhoneLabel'),
                    hasError: true,
                    theme: IQThemeV2,
                    required: false,
                  }}
                  hasType={false}
                  countryCallingCodeEditable={false}
                  international
                />
              </Grid>
              <Grid item mb={1} xs={12}>
                <Typography variant="subtitle2" component="h3" fontSize={16}>
                  {t('pages.ccByDash.solutionStrategy.utilizeSamePrimaryUrl')}
                </Typography>
                <RadioGroup
                  aria-labelledby="utilize-url"
                  name="utilizeSamePrimaryUrl"
                  value={utilizeSamePrimaryUrl}
                  onChange={(e) => setUtilizeSamePrimaryUrl(e.target.value)}
                  sx={{ display: 'flex', flexDirection: 'row' }}
                >
                  {yesNoOptions.map(option => (
                    <FormControlLabel
                      key={option.value}
                      {...register('utilizeSamePrimaryUrl', {
                        validate: (value) => schemaValidate(value, 'utilizeSamePrimaryUrl', schema),
                      })}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </Grid>
            </Grid>
            )}

            {showSmsAiAgentFields && isDifferentPrimaryUrl && (
            <Grid item mb={1} xs={12}>
              <Typography variant="subtitle2" component="h3" fontSize={16}
                color={errors.siteUrl ? 'error.main' : 'common.black'}
              >
                {t('pages.ccByDash.solutionStrategy.provideSiteUrl')}
              </Typography>
              <TextField
                hiddenLabel
                required
                name="siteUrl"
                error={!!errors.siteUrl}
                fullWidth
                {...register('siteUrl', {
                  validate: (value) => schemaValidate(value, 'siteUrl', schema),
                })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LinkOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <ErrorText
                hasError={!!errors.siteUrl}
                errorText={t('pages.ccByDash.details.websiteUrlError')}
              />
            </Grid>
            )}

            {/* Additional Notes */}
            <Grid item xs={12} mb={3}>
              <NeFormTextArea
                {...register('additionalNotes')}
                id="additionalNotes"
                name="additionalNotes"
                labelText={t('pages.ccByDash.solutionStrategy.additionalNotesLabel')}
                fullWidth
                rowCount={2}
                fontLabelWeight="600"
              />
            </Grid>

          </Grid>
        </FormProvider>
      </ValidationProvider>
      <CoNavigationConfirm
        showDialog={isDirty && !(isSubmitSuccessful || isSubmitting)}
      />
    </DefaultPageLayout>
  );
}
