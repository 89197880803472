/* eslint-disable max-len */
import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
  IconButton,
} from '@mui/material';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  Children, useEffect, useMemo, useRef, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  accordionStyles, getSectionTitle, renderSectionContent,
} from 'pages/overview/Overview';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOrder,
  getOrderItems,
  selectOrderItems,
  selectOrdersContent,
  updateOrderFlow,
} from 'services/ordersSlice';
import { useTranslation } from 'react-i18next';
import { getOrderBusinessByOrderId } from 'services/businessSlice';
import { getOrderDetails, selectOrderDetails } from 'services/myOrdersSlice';
import {
  getOrderItemsWithCampaignId,
  getOrderItemsWithCidStatus,
  selectOrderItemsWithCidStatus,
} from 'services/campaignSlice';
import { getCIDStatus, getOrderItemStatus } from 'pages/orders/utils';
import { sortOrderItems } from 'services/menus';
import { OrderFlow } from 'shared/constants';
import { addons } from 'pages/overview/OverviewConstants';
import { selectUserAccess, selectUserRole } from 'services/userSlice';
import { renderStatusPill } from '../OrdersTable';
import CidBanner from './CidBanner';
import { useNavigationHandler } from "hooks/useNavigationHandler";

const customProducts = ['CustomWebsite', 'CustomLandingPage'];

export default function OrderSummary() {
  const { t } = useTranslation();
  const { orderId, productId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orderItems = useSelector(selectOrderItems);
  const orderDetails = useSelector(selectOrderDetails);
  const orderData = useSelector(selectOrdersContent);
  const campaignStatuses = useSelector(selectOrderItemsWithCidStatus);
  const [sections, setSections] = useState([]);
  const [cidStatus, setCidStatus] = useState('');
  const [expandAll, setExpandAll] = useState(false);
  const [showCIDModal, setShowCIDModal] = useState(false);

  const access = useSelector(selectUserAccess);

  const isFailed = cidStatus === 'Failed' || cidStatus === 'Pending';
  const [showCIDBanner, setShowCIDBanner] = useState(false);
  const expandedAccordionRef = useRef(null);
  const isCobaltRole = useSelector(selectUserRole);

  const { orderFlow } = useSelector(selectOrdersContent);
  const isPartnerWorkflow = orderItems.content.some(item => item.partnerAccountId !== null);
  const isCampaignIdPending = orderItems.content?.some(item => item.campaignId === null);

  const isBannerVisibleForPartnerFlow = isPartnerWorkflow && isCampaignIdPending
      && (isCobaltRole !== 'partner' && access.isManager);
  const isCefOrder = useMemo(() => orderFlow !== OrderFlow.INTERNAL, [orderFlow]);


  useMemo(() => {
    setShowCIDBanner(isFailed);
  }, [isFailed]);

  const isPlProduct = orderItems?.content?.filter(item => (
      item.offeringType === 'PremiumListing' && !item.healthcare))?.length > 0;

  const isHCProduct = orderItems?.content?.filter(item => (
      item.offeringType === 'PremiumListing' && item.healthcare))?.length > 0;

  const getOrderData = async () => {
    await dispatch(getOrderDetails(orderId));
    dispatch(getOrder(orderId));
    dispatch(getOrderBusinessByOrderId(orderId));
    dispatch(getOrderItems(orderId));
    dispatch(getOrderItemsWithCampaignId(orderId));
    dispatch(getOrderItemsWithCidStatus(orderId));
  };

  useEffect(() => {
    getOrderData();
  }, [orderId]);

  useEffect(() => {
    if (campaignStatuses.length > 0 && orderItems.content) {
      const sortedItems = sortOrderItems(orderItems.content, true);
      const itemStatuses = sortedItems.map(item => getCIDStatus(campaignStatuses, item.id));
      if (itemStatuses.includes('Failed')) {
        setCidStatus('Failed');
      } else setCidStatus('Pending');
    }
  }, [campaignStatuses, orderItems]);

  const scrollToExpandedAccordion = () => {
    if (expandedAccordionRef.current) {
      expandedAccordionRef.current.scrollIntoView({
        block: 'start',
      });
    }
  };

  const getTrackerStatus = (item) => {
    const orderItem = orderDetails?.filter(details => details?.orderItem?.id === item.id)?.[0];
    const status = orderItem?.orderStatus?.neonStatus;
    const lastCompletedStep = orderItem?.orderStatus?.lastCompletedStep;

    // Following product statuses are coming in the wrike attribute
    if (['ChatProduct', 'ChatPlatinumProduct', 'LandingPage'].includes(item.pdOfferingType)) {
      const wrikeStatus = orderItem?.orderStatus?.wrikeStatus;
      return wrikeStatus === 'Completed' ? wrikeStatus : status;
    }

    if (lastCompletedStep === 'Order Submitted' || lastCompletedStep === null) {
      if ([item.pdOfferingType, item.offeringType]?.includes('PremiumListing') || status?.includes('Payment') || status?.includes('Assignments')) {
        return status;
      }
    }

    return lastCompletedStep;
  };

  useEffect(() => {
    if (Object.keys(orderData).length > 0 && orderData.orderFlow === OrderFlow.SALES_FORCE) {
      dispatch(updateOrderFlow(OrderFlow.SALES_FORCE));
    }
  }, [orderData]);

  const handleStatus = (orderDetails, item, campaignStatuses) => {
    const isCustomProduct = customProducts.includes(item.offeringType);

    if (isCustomProduct) {
      return orderDetails?.filter(details => details?.orderItem?.id === item.id)?.[0]?.orderStatus?.neonStatus;
    }

    return getOrderItemStatus(orderDetails, campaignStatuses, item.id);
  };

  useEffect(() => {
    if (orderItems && orderDetails) {
      const products = orderItems.content.filter(item => !addons.includes(item.offeringType))
      .map(item => ({
        title: item.offeringType === 'ProductsData' ? item.pdName : item.offeringType,
        isOpen: item.id === +productId,
        ...item,
        showStatus: true,
        status: handleStatus(orderDetails, item, campaignStatuses),
        trackerStatus: getTrackerStatus(item),
      }));
      const productIsPl = orderItems.content
      .filter((item) => (item.id === +productId && item.offeringType === 'PremiumListing')).length > 0;
      setSections([
        {
          title: t('pages.overview.salesAgent.title'),
          isOpen: false,
          showStatus: false,
        },
        {
          title: t('pages.overview.business.title'),
          isOpen: productIsPl,
          showStatus: false,
        },
        ...products,
      ]);
      scrollToExpandedAccordion();
    }
  }, [orderItems, campaignStatuses]);

  const toggleAllSections = () => {
    setExpandAll(!expandAll);
    const copy = [...sections].map(section => ({...section, isOpen: !expandAll}));
    setSections(copy);
  };

  const openBusinessAndSalesAgent = () => {
    const copy = [...sections].map(section => ({
      ...section,
      isOpen: [t('pages.overview.business.title'), t('pages.overview.salesAgent.title')]
      .includes(section.title) ? true : section.isOpen,
    }));
    setSections(copy);
  };

  const handleChange = (index) => {
    const copy = [...sections];
    copy[index].isOpen = !sections[index].isOpen;
    setSections(copy);
  };

  const renderBanner = () => {
    if ((isFailed && showCIDBanner && isCefOrder) || showCIDModal || isBannerVisibleForPartnerFlow) {
      return <CidBanner setShowBanner={setShowCIDBanner} bannerType="ERROR" showModal={showCIDModal} />;
    }
    return null;
  };

  return (
      <Box className="summary-container" sx={{width: '100%'}}>
        {renderBanner()}
        <Grid item xs={12}>
          <IconButton onClick={() => navigate('/orders')} sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            textDecoration: 'none',
            color: '#000000',
            paddingLeft: 0,
            '&:hover': {
              background: 'transparent',
            },
          }}
          >
            <ChevronLeftIcon fontSize="large"/>
            <Typography>My Orders</Typography>
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
            <Typography variant="h3" marginTop={2} marginBottom={0}>Order Summary</Typography>
            <Button onClick={toggleAllSections}
                    sx={{alignSelf: 'flex-end', textDecoration: 'underline'}}>
              {expandAll ? ' Collapse All' : 'Expand All'}
            </Button>
            {Children.toArray(sections?.map((section, index) => (
                <Accordion
                    expanded={sections[index]?.isOpen}
                    onChange={() => handleChange(index)}
                    disableGutters
                    sx={accordionStyles}
                    ref={sections[index]?.isOpen ? expandedAccordionRef : null}
                >
                  <AccordionSummary expandIcon={<ExpandCircleDownOutlinedIcon color="primary"/>}>
                    <Typography variant="h5" marginRight={1}>
                      {getSectionTitle(section)}
                    </Typography>
                    {section.showStatus && renderStatusPill(section.status)}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      {sections[index]?.isOpen
                          && renderSectionContent(section.title, section, isPlProduct, isHCProduct, false, true, false, openBusinessAndSalesAgent, setShowCIDModal)}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
            )))}
          </Box>
        </Grid>
      </Box>
  );
}
