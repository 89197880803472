import * as React from 'react';
import { FormHelperText, useTheme } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

const ErrorText = ({ hasError, errorText }: { hasError: boolean | (() => boolean); errorText: string }) => {
  const theme = useTheme();

  if (!hasError) {
    return null;
  }

  return (
    <FormHelperText role="alert" sx={{
      mt: '2px',
      color: theme.palette.error.main,
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
    }}
    >
      <ErrorIcon fontSize="inherit" style={{ fontSize: theme.typography.fontSize }} />
      {errorText}
    </FormHelperText>
  );
};

export default ErrorText;
